import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/src/templates/default.tsx";
import ProjectList from 'components/ProjectList';
import projects from '../../content/project.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.400000000000006%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZUlEQVQoz23RSU+TURTG8VtElMZKacso9GXoO3QgVpkLOKCMiohIREBQaaWtQAgENMQFURaSKAppxGhAExFIdKHBKCxYmCju3BmjO7+AX+Fv+goEjIuTk7v55bnPEalGL+n78slMKCbLWo6ScgJ35mmK1C7KPD1UeHqocwcZcQTpU4LUat1I5lIShYxZyBtbISnWg91UiIhCksVHju0IWno1rowGSnPb6HKGOasFaXCF8DuC3M0IElbCVMiXkCzl2GJdOvgXVTALlQShItKMXrKt5TjT65BtteTLbZxyBxmTQkzkDTHi7OdO0U0eF4zSnT9EkXoZLa2GdONhrDEaFoOqg/oYNITFoJAc50Ey+zhfM8jTyDKPxhZ47p/h0/AiX6dXWTsX4cvkB5pKhjlo70RNridp9yGMIgejyNpKGR2x+bAYNI7lN9NSN8Dc1DIrD96xNrLIz4kVvrfM8vnGawYu3KP/6hShjnHaGwc5U3mFElc9tl0aCcKhw2Kzh2jSOJFJWryXmYcveXb/Fd+W1vk9/ZFfg2/58WKdN5H3zD9ZZWFulfnZZW6PRhi/NaX3uQ10bJVrjVH1TixCxRbnpV25yHzOEEum60xKvYRzQlSrIcrdIbxSK7nWKuzmMlLjvVhiVKLWBujYcTG9AqFgitHITqokoIUYk3vpU8M0u8Mc9YQocwUocLTqx5QSfaTszSPRIG8H/4VlHY1+JcNUTJPSTacWpNF5jWPuHnyuAIVyB3n2RhzJxzlgKsAW6/wfuBOPpt0vsnX0pNNPlTNAhcuPz9VNkdKJN6sZNa0au7mE5D0e/gB69VBiI5umbwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Photo by Fakurian Design on Unsplash",
          "title": "Photo by Fakurian Design on Unsplash",
          "src": "/static/4e148aea63b58ac347f76c8d57240cbe/da8b6/cover.png",
          "srcSet": ["/static/4e148aea63b58ac347f76c8d57240cbe/43fa5/cover.png 250w", "/static/4e148aea63b58ac347f76c8d57240cbe/c6e3d/cover.png 500w", "/static/4e148aea63b58ac347f76c8d57240cbe/da8b6/cover.png 1000w", "/static/4e148aea63b58ac347f76c8d57240cbe/5ece7/cover.png 1200w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h1>{`JUXT Design`}</h1>
    <h2>{`Hello`}</h2>
    <p>{`Hello world! This is JUXT Design. Join us on this journey to advocate design & user experience! We'll be sharing design tips to make your website & product awesome!`}</p>
    <h2>{`Reach out`}</h2>
    <ul>
      <li parentName="ul">{`Email: `}<a parentName="li" {...{
          "href": "mailto:hello@juxtdesign.cc"
        }}>{`hello@juxtdesign.cc`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://instagram.com/juxtdesigncc"
        }}>{`@juxtdesignco`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/juxtdesigncc"
        }}>{`@juxtdesignco`}</a></li>
    </ul>
    <h2>{`Support our projects!`}</h2>
    <ProjectList data={projects} mdxType="ProjectList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      